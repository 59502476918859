import { Money } from "@commercetools/platform-sdk"
import * as Sentry from "@sentry/browser"
import { Language, Currency } from "@sixty-six-north/i18n"
import {
  FontWeight,
  HouseIcon,
  ImageVariants,
  LinkVariants,
  OutlineLightButton,
  ParagraphSizeEnum,
  ProductCardVariant,
  TechnicalParagraph,
  TextVariants
} from "@sixty-six-north/ui-system"
import { Option } from "funfix-core"
import { standardisedCentAmount } from "i18n/CurrencyFormatter"
import { TFunction } from "i18next"
import _isEmpty from "lodash/isEmpty"
import { NextPage, PreviewData } from "next"
import dynamic from "next/dynamic"
import Head from "next/head"
import { PrismicDocumentLink } from "prismic/slices/PrismicLink"
import React, { Fragment, useEffect, useMemo, useState } from "react"
import { useTranslation, Trans } from "react-i18next"
import { Box, Flex } from "theme-ui"
import { useSEOTitleProduct } from "utils/SEO"
import { useCartNotification, useCartService } from "../cart/CartServiceHook"
import { alternateAndCanonicalUrlRelLinks } from "../components/AlternateHrefs"
import { productApiUrl } from "../Config"
import { IS, EU } from "../i18n/Region"
import { usePreferredLanguage, useStoreContext } from "../i18n/StoreHooks"
import { GlobalProps } from "../next/GlobalProps"
import { SimplePrismicDocument } from "../prismic/PrismicModels"
import { useRouter } from "../routing/useRouter"
import {
  useGoogleAnalytics,
  RawAnalyticsProductDetails
} from "../tagmanager/AnalyticsHooks"
import { createLogger } from "../utils/createLogger"
import { assetsTaggedFor } from "./AssetsTaggedFor"
import { StoreStockInformation } from "./AvailabilityModal"
import ProductCard from "./components/ProductCard"
import {
  DetailedProductInformation,
  ColorwayVariant,
  Colorway
} from "./models/DetailedProductInformation"
import { DomainCategory } from "./models/DomainCategory"
import { SimpleAsset } from "./models/SimpleAsset"
import { SelectColor } from "./product/ColorSelectorNew"
import {
  ProductContent,
  ProductCore as ProductComponent
} from "./product/Product"
import {
  TechnicalSpec,
  TechnicalSpecifications
} from "./product/TechnicalSpecs"
import { ProductBreadCrumbs } from "./ProductBreadCrumbs"
import { useColorwaySizes, useStockInformation } from "./ProductHooks"
import { StockLevel } from "./ProductReduction"
import { RecommendedLooks } from "./Recommendations"
import {
  ModelDetail,
  GarmentComposition,
  getGarmentCompositionValue,
  nonEmpty,
  Style,
  KeyAndValue,
  skuComponents,
  SKU
} from "./VariantProxy"

const logger = createLogger("ProductPage")

const SizeGuideModal = dynamic(
  import("./SizeGuideModal").then(it => it.SizeGuideModal)
)

const AvailabilityModal = dynamic(
  import("./AvailabilityModal").then(it => it.AvailabilityModal)
)

const NotifyBackInStockModal = dynamic(
  import("./NotifyBackInStock/Modal").then(it => it.NotifyBackInStockModal)
)

interface ProductPageProps {
  product: DetailedProductInformation
  productPrismicData: SimplePrismicDocument | null
  previewData?: PreviewData
  selectedColor: string | null
  selectedSize: string | null
  category: DomainCategory | null
}

const ModelDetailDescription: React.FC<{ details: ModelDetail[] }> = ({
  details
}) => {
  const { t } = useTranslation("product")

  const textDetails = details.map((it, index) => {
    const { model, height, chest, waist, size } = it
    if (!height) return null
    const { centimetres, feet, inches } = height
    const modelHeight = t("modelHeight", {
      heightCm: centimetres,
      heightFt: feet,
      heightInches: inches
    })
    const modelChest =
      chest &&
      t("modelChest", { cm: chest.centimetres, inches: chest.totalInches })
    const modelWaist =
      waist &&
      t("modelWaist", { cm: waist.centimetres, inches: waist.totalInches })
    const modelWearingSize = size && t(`${model}WearingSize`, { size })

    return (
      <TechnicalParagraph
        key={`model-measurements-${modelHeight}-${index}`}
        variant={ParagraphSizeEnum.small}
        as="p"
      >
        {t(model)} {modelHeight}
        {modelChest}
        {modelWaist}
        {modelWearingSize}
      </TechnicalParagraph>
    )
  })

  return <Fragment>{textDetails}</Fragment>
}

const extractProductKeyFromSku = (key: SKU | undefined) => {
  return key?.split("-")[0]
}

const getRecommendationsCollectionForProduct = (
  recommendations: RecommendedLooks[] = [],
  t: TFunction,
  language: Language
) =>
  recommendations.map((r, i) => ({
    ...r,
    looks:
      r.looks?.map(l => ({
        title: t(l.title),
        products: l.products.map((lp, j) => () => (
          <ProductCard
            index={j}
            key={`product-${i}-${j}`}
            product={lp.product}
            badge={lp.badge}
            border={false}
            categorySlug={lp.categorySlug}
            parentCategories={lp.parentCategories}
            imageRatio={ImageVariants.ratio_3x4}
            productCardVariant={ProductCardVariant.EXP_OCT_22}
          />
        ))
      })) || []
  }))

const WarrantyAndRepair = () => {
  const { t } = useTranslation("product")
  return (
    <Fragment>
      {t("repairsForLife", { defaultValue: "Repairs for life." })}
      {` `}
      <PrismicDocumentLink
        documentId={"XweRvxQAACQAnhhE"}
        sx={{ variant: `links.${LinkVariants.standardNoUnderline}` }}
      >
        {t("readOurWarrantyPromise", {
          defaultValue: "Read our full warranty and repair promise"
        })}
      </PrismicDocumentLink>
    </Fragment>
  )
}

const ActionLink = ({ translationKey, onClick }) => {
  const { t } = useTranslation("product")
  return (
    <Box
      as="button"
      aria-label={t(translationKey)}
      sx={{
        variant: "buttons.accessible",
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      <Box
        as="span"
        sx={{
          color: "blue.0",
          variant: `text.${TextVariants.label3}`,
          fontWeight: FontWeight.textBold,
          textDecoration: "underline",
          ":hover": {
            color: "blue.1"
          }
        }}
      >
        {t(translationKey)}
      </Box>
    </Box>
  )
}

export class HierarchicalProductProxy {
  private readonly product: DetailedProductInformation

  constructor(product: DetailedProductInformation) {
    this.product = product
  }

  public name(language: Language): Option<string> {
    return Option.of(this.product.name)
  }

  get listingDescription(): Option<string> {
    return Option.of(this.product.listingDescription)
  }

  public get rootCategories(): DomainCategory[] {
    return this.product.categories.filter(
      it => it && it.ancestors && it.ancestors.length === 0
    )
  }

  public get hasNoColorways(): boolean {
    return Object.keys(this.product.colorways).length === 0
  }

  public selectedVariant(
    color: string | null,
    size: string | null,
    language: Language
  ): ColorwayVariant {
    const inStockVariants = this.colorways.flatMap(v => v.inStockVariants)
    let matchingVariants: ColorwayVariant[] = []
    if (color) {
      matchingVariants = this.colorway(color).inStockVariants
    }

    if (size) {
      const variants = color ? matchingVariants : inStockVariants
      matchingVariants = variants.filter(v => v.size.label[language] === size)
    }

    if (matchingVariants.length > 0 && (color || size)) {
      return matchingVariants[0]
    } else if (inStockVariants.length > 0) {
      return inStockVariants[0]
    } else {
      return this.defaultColorway.variants[0]
    }
  }

  public get defaultColorway(): ColorwayProxy {
    const [_, defaultColor] = this.product.masterSku?.split("-") || []
    return (
      this.colorways.find(c => c.colorCode === defaultColor) ||
      this.colorways[0]
    )
  }

  public garmentComposition(
    language: Language
  ): { name: string; value: string }[] {
    const getCompositionName = (composition: GarmentComposition[]) => {
      const garment = getGarmentCompositionValue(
        "garment",
        composition,
        language
      ).join(" ")
      const fabric = getGarmentCompositionValue(
        "fabric",
        composition,
        language
      ).join(" ")
      return [garment, fabric].filter(nonEmpty).join(" - ")
    }
    const getCompositionValue = (composition: GarmentComposition[]) => {
      const layerComposition = getGarmentCompositionValue(
        "layer-composition",
        composition,
        language
      ).join(", ")
      const brandedSupplier = getGarmentCompositionValue(
        "branded-supplier",
        composition,
        language
      ).join(", ")
      const certificate = getGarmentCompositionValue(
        "tpd-certificates",
        composition,
        language
      ).join(", ")

      return [layerComposition, brandedSupplier, certificate]
        .filter(nonEmpty)
        .join(" | ")
    }

    return (this.product.attributes.garmentComposition || []).map(comp => {
      return {
        name: getCompositionName(comp),
        value: getCompositionValue(comp)
      }
    })
  }

  public style(): Option<Style> {
    return Option.of(this.product.attributes.style)
  }

  public styleName(language: Language): Option<KeyAndValue> {
    return this.style().flatMap(it => {
      return Option.of({
        key: it.key,
        value: it.label[language]
      })
    })
  }

  public suitableFor(language: Language): KeyAndValue[] {
    return (this.product.attributes.suitableFor || []).map(it => ({
      key: it.key,
      value: it.label[language]
    }))
  }

  public functionality(language: Language): KeyAndValue[] {
    return (this.product.attributes.functionality || []).map(it => ({
      key: it.key,
      value: it.label[language]
    }))
  }

  public layering(language: Language): KeyAndValue[] {
    return (this.product.attributes.layering || []).map(it => ({
      key: it.key,
      value: it.label[language]
    }))
  }

  public tpdSuitableFor(language: Language): KeyAndValue[] {
    return (this.product.attributes.suitableFor || []).map(it => ({
      key: it.key,
      value: it.label[language]
    }))
  }

  public tpdWashingInstructions(language: Language): KeyAndValue[] {
    return (this.product.attributes.washingInstructions || []).map(it => {
      return {
        key: it.key,
        value: it.label[language]
      }
    })
  }

  public shells(language): KeyAndValue[] {
    return (this.product.attributes.shell || []).map(it => {
      return {
        key: it.key,
        value: it.label[language]
      }
    })
  }

  public get colorways(): ColorwayProxy[] {
    return Object.keys(this.product.colorways)
      .map(it => this.colorway(it))
      .sort((left, right) => left.index - right.index)
  }

  colorway(color: string = ""): ColorwayProxy {
    const colorway = this.product.colorways[color]
    return colorway ? new ColorwayProxy(colorway) : this.defaultColorway
  }

  technicalSpecs(language: Language, t: TFunction<string, undefined>) {
    const garmentComposition = this.garmentComposition(language).map(it => ({
      ...it,
      values: [
        {
          key: it.value,
          value: it.value
        }
      ]
    }))

    const technicalSpecs: TechnicalSpec[] = [
      ...garmentComposition,
      {
        name: t("washingInstructions"),
        values: this.tpdWashingInstructions(language)
      },
      { name: t("shell"), values: this.shells(language) },
      {
        name: t("suitableFor"),
        values: this.suitableFor(language)
      },
      {
        name: t("functionality"),
        values: this.functionality(language)
      },
      { name: t("layering"), values: this.layering(language) },
      {
        name: t("style"),
        values: this.styleName(language)
          .map(it => [it])
          .getOrElse([])
      }
    ].filter(it => it.values.length > 0)

    return technicalSpecs
  }

  colorwaysFilteredByTerms(filterColors: string[] = []) {
    return this.colorways.find(c => filterColors.includes(c.term))
  }
}

export class ColorwayProxy {
  private readonly colorway: Colorway
  constructor(colorway: Colorway) {
    this.colorway = colorway
  }
  public get inStockVariants(): ColorwayVariant[] {
    return this.variants.filter(v => v.availability !== "out-of-stock")
  }
  public get assets(): SimpleAsset[] {
    return this.colorway?.assets || []
  }

  public get enableBackInStockNotifications(): boolean {
    return this.colorway.enableBackInStockNotification
  }

  public get variants(): ColorwayVariant[] {
    return this.colorway.variants
  }

  public get index(): number {
    return this.colorway.index
  }

  public get availability(): string {
    return this.colorway.colorwayAvailability
  }

  public get hexcode(): string {
    return this.colorway.hexcode
  }
  public get colorCode(): string {
    return this.colorway.color
  }

  public get term(): string {
    return this.colorway.term
  }

  public modelDetails(): ModelDetail[] {
    return this.colorway.modelDetail || []
  }

  public modelDetailsFor(genders: string[]): ModelDetail[] {
    const modelSexForGenders = this.modelSexFor(genders)

    return this.modelDetails().filter(modelDetail =>
      modelSexForGenders.includes(modelDetail.model)
    )
  }

  private pricesFor(currency: Currency) {
    return Option.of(this.colorway.prices).flatMap(prices =>
      Option.of(prices.find(it => it.value.currencyCode === currency))
    )
  }

  public price(currency: Currency): Option<Money> {
    return this.pricesFor(currency).map(it => it.value)
  }

  public discountedPrice(currency: Currency): Option<Money> {
    return this.pricesFor(currency).flatMap(price =>
      Option.of(price.discounted).map(it => it.value)
    )
  }

  private modelSexFor(genders: string[]) {
    const modelSexForGenders: string[] = (
      genders || ["femaleModel", "maleModel"]
    ).flatMap(gender => {
      if (gender === "women") {
        return "femaleModel"
      } else if (gender === "men") {
        return "maleModel"
      } else {
        return ["femaleModel", "maleModel"]
      }
    })

    return _isEmpty(modelSexForGenders)
      ? ["femaleModel", "maleModel"]
      : modelSexForGenders
  }

  stockLevel(currentSKU: SKU): StockLevel {
    return (
      this.colorway.variants.find(v => v.sku === currentSKU)?.availability ||
      "out-of-stock"
    )
  }

  isInStock(currentSKU: SKU) {
    return this.stockLevel(currentSKU) !== "out-of-stock"
  }

  name() {
    return this.colorway.name
  }

  selectColor(
    language: Language,
    setCurrentSKU: (sku: SKU) => void
  ): SelectColor {
    return {
      colorName: this.name(),
      colorHex: this.hexcode,
      onSelect: () =>
        setCurrentSKU(
          this.colorway.variants.filter(
            cv => cv.availability !== "out-of-stock"
          )[0]?.sku || this.colorway.variants[0].sku
        )
    } as SelectColor
  }

  variant(sku: SKU): ColorwayVariant | undefined {
    return this.colorway.variants.find(v => v.sku === sku)
  }
}

function discountedOrFullPrice(
  colorway: ColorwayProxy,
  currency: Currency
): Option<Money> {
  return colorway.discountedPrice(currency).orElse(colorway.price(currency))
}

export function discountedOrFullPriceInEurosFor(
  colorway: ColorwayProxy
): Money {
  return discountedOrFullPrice(colorway, IS.currency)
    .orElse(discountedOrFullPrice(colorway, EU.currency))
    .getOrElse({ centAmount: 0, currencyCode: EU.currency })
}

const ProductPage: NextPage<ProductPageProps & GlobalProps> = ({
  product,
  productPrismicData,
  previewData,
  selectedColor,
  selectedSize,
  category
}) => {
  const { currency, formatCurrency, country } = useStoreContext()
  const language = usePreferredLanguage()
  const store = useStoreContext()
  const freeShippingThreshold = store.freeShippingThreshold
  const { t } = useTranslation("product")
  const router = useRouter()

  const cartNotification = useCartNotification()

  const productCategory = category || product.categories[0]

  const proxy = new HierarchicalProductProxy(product)

  const [currentSKU, setCurrentSKU] = useState<SKU>(
    proxy.selectedVariant(selectedColor, selectedSize, language).sku
  )

  const allColors: SelectColor[] = proxy.colorways
    .filter(it => it)
    .map(it => it.selectColor(language, setCurrentSKU))
  const color = skuComponents(currentSKU).color
  const currentColorway: ColorwayProxy = proxy.colorway(color)

  const currentColor: SelectColor = {
    colorName: currentColorway.name(),
    colorHex: currentColorway.hexcode
  }
  const { currentSize, allSizes } = useColorwaySizes(
    currentSKU,
    currentColorway,
    setCurrentSKU
  )

  const rawAnalyticsProductDetails: () => RawAnalyticsProductDetails = () => ({
    sku: currentSKU,
    name: product.name,
    category: productCategory,
    price: discountedOrFullPriceInEurosFor(currentColorway)
  })

  const [currentRawAnalyticsData, setCurrentRawAnalyticsData] =
    useState<RawAnalyticsProductDetails>(rawAnalyticsProductDetails())

  useEffect(() => {
    if (extractProductKeyFromSku(currentSKU) !== product.key) {
      setCurrentSKU(
        proxy.selectedVariant(selectedColor, selectedSize, language).sku
      )
    }
  }, [product.key])

  useEffect(() => {
    setCurrentRawAnalyticsData(rawAnalyticsProductDetails())
  }, [currentSKU])

  const currentImages = (currentColorway.assets || [])
    .filter(assetsTaggedFor(productCategory.ancestors))
    .map(it => ({
      url: it.url,
      title: it.label || "",
      tags: it.tags
    }))

  const cartService = useCartService()

  const collection = useMemo(
    () =>
      getRecommendationsCollectionForProduct(
        product.attributes.recommendations,
        t,
        language
      ),
    [product.key]
  )

  const [stockInformation, setStockInformation] = useState<
    StoreStockInformation[]
  >([])

  const [stockInformationVisible, setStockInformationVisible] = useState(false)
  const [stockInformationLoading, setStockInformationLoading] = useState(false)

  const price = currentColorway
    .price(currency)
    .map(p => formatCurrency(p))
    .getOrElse("No price")

  const discountedPrice = currentColorway
    .discountedPrice(currency)
    .map(p => formatCurrency(p))
    .getOrElse("")

  const currentPrice = discountedPrice
    ? currentColorway.discountedPrice(currency).value
    : currentColorway.price(currency).value

  const name = product.name
  const googleAnalytics = useGoogleAnalytics()

  useEffect(() => {
    if (router.query["prev"] === "impressionView") {
      googleAnalytics.reportImpressionClick(currentRawAnalyticsData)
    }
    googleAnalytics.reportProductPageView(currentRawAnalyticsData)
  }, [])

  const [showSizeGuide, setShowSizeGuide] = useState<boolean>(false)
  const [addToBagError, setAddToBagError] = useState(false)
  const [showNotifyBackInStock, setShowNotifyBackInStock] = useState(false)

  const suitableFor = (product.attributes.suitableFor || []).map(it => it.key)

  const modelDetails = currentColorway.modelDetailsFor(
    productCategory.ancestors.map(topCategory => topCategory.key)
  )

  const addToBag = async (notify: boolean = true): Promise<unknown> => {
    try {
      return cartService
        .addLineItem({
          sku: currentSKU!,
          quantity: 1
        })
        .then(it => {
          if (notify) {
            cartNotification.notify({
              name: product.name,
              price,
              discountedPrice,
              size: currentSize.label,
              color: currentColorway.name[language],
              imageUrl: currentImages[0] && currentImages[0].url,
              imageAlt: currentImages[0] && currentImages[0].title
            })
            setAddToBagError(false)
          }
          return it
        })
        .then(() => googleAnalytics.reportAddToBag(currentRawAnalyticsData))
        .catch(err => {
          setAddToBagError(true)
          logger.error("Failed adding item to basket", err)
          Sentry.captureException("Failed adding item to basket", err)
        })
    } catch (e) {
      setAddToBagError(true)
      return Promise.reject(e)
    }
  }

  const detailContents: ProductContent[] = []

  // details
  detailContents.push({
    label: t(`product:descriptionAndFit`),
    elements: (
      <Flex
        sx={{
          flexDirection: ["column", null, null, null, "row"],
          gap: [16, null, null, null, 80]
        }}
      >
        <Box sx={{ "& > p + p": { mt: 16 } }}>
          {(product.description || "").split("\n").map((it, idx) => (
            <TechnicalParagraph
              as="p"
              key={`details-and-features-${idx}`}
              variant={ParagraphSizeEnum.small}
            >
              {it}
            </TechnicalParagraph>
          ))}

          {(product.attributes.fitDescription || "")
            .split("\n")
            .map((it, idx) => (
              <TechnicalParagraph
                as="p"
                key={`details-and-features-fit-${idx}`}
                variant={ParagraphSizeEnum.small}
              >
                {it}
              </TechnicalParagraph>
            ))}
          <ModelDetailDescription
            key={`model-wearing`}
            details={modelDetails}
          />
        </Box>
      </Flex>
    )
  })

  // tech specs
  const technicalSpecs = useMemo(
    () => proxy.technicalSpecs(language, t),
    [product.key]
  )

  if (technicalSpecs.length > 0) {
    detailContents.push({
      label: t("detailsAndCare"),
      elements: (
        <TechnicalSpecifications
          warrantyAndRepair={<WarrantyAndRepair />}
          values={technicalSpecs}
          previewData={previewData}
        />
      )
    })
  }

  const ReturnPolicyLink: React.FC = () => {
    const { country: storeUri, isShoppingInIceland } = useStoreContext()
    const href = isShoppingInIceland()
      ? "https://www.66north.com/is/upplysingar/skil"
      : `https://www.66north.com/${storeUri}/information/returns`
    return (
      <a href={href} sx={{ variant: `links.${LinkVariants.standard}` }}>
        {t("here")}
      </a>
    )
  }

  detailContents.push({
    label: t("shippingAndReturns"),
    elements: (
      <>
        <TechnicalParagraph as="p" variant={ParagraphSizeEnum.small}>
          {t(
            "layout:freeShippingMessage",
            "Free shipping on orders over {{ amount }}",
            { amount: freeShippingThreshold }
          )}
        </TechnicalParagraph>
        <TechnicalParagraph
          as="p"
          sx={{ mt: 16 }}
          variant={ParagraphSizeEnum.small}
        >
          {t("product:returnInfo")}
        </TechnicalParagraph>
        <TechnicalParagraph
          as="p"
          sx={{ mt: 16 }}
          variant={ParagraphSizeEnum.small}
        >
          <Trans i18nKey="product:returnPolicy">
            <ReturnPolicyLink key={"return-policy"} />
          </Trans>
        </TechnicalParagraph>
      </>
    )
  })

  const loadStockInformation = async () => {
    googleAnalytics.reportStoreInventoryClick(currentRawAnalyticsData)

    const data = await fetch(
      productApiUrl(
        `/by-sku/${encodeURIComponent(
          currentSKU
        )}/inventory/by-store?store=${country}`
      )
    )
    const { results = [] } = await data.json()
    return results
  }

  const productSchemaObject = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: product.name,
    description: product.seoDescription || product.description,
    sku: currentSKU,
    image: currentImages.map(img => img.url),
    brand: {
      "@type": "Brand",
      name: "66°North"
    },
    offers: {
      "@type": "Offer",
      priceCurrency: currency,
      price: standardisedCentAmount(currency, currentPrice as Money),
      itemCondition: "https://schema.org/NewCondition",
      availability: "https://schema.org/InStock"
    }
  }

  // Product care --- to build
  // Details and features -- prismic content
  return (
    <>
      <Head>
        <title>
          {useSEOTitleProduct(
            productCategory,
            language,
            product.seoTitle,
            product.key
          )}
        </title>
        <meta name={"description"} content={product.seoDescription} />
        {alternateAndCanonicalUrlRelLinks({
          en: `${product.slug.en || product.slug.is}/p/${product.key}`,
          is: `${product.slug.is || product.slug.en}/p/${product.key}`
        })}

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(productSchemaObject)
          }}
        />
      </Head>
      <ProductBreadCrumbs category={productCategory} />
      <ProductComponent
        previewData={previewData}
        productPrismicData={productPrismicData}
        suitableFor={suitableFor}
        showNotifyBackInStock={currentColorway.enableBackInStockNotifications}
        product={{
          id: product.id,
          key: product.key,
          name,
          sku: currentSKU,
          stockLevel: currentColorway.stockLevel(currentSKU),
          description: product.attributes.detailsShortDescription || "",
          phoneticSpelling: product.attributes.phoneticSpelling,
          price,
          colorCode: skuComponents(currentSKU).color,
          category: productCategory.ancestors[0]?.key,
          discountedPrice,
          galleryImages: currentImages,
          size: {
            selected: currentSize,
            sizes: allSizes
          },
          color: {
            selected: currentColor,
            colors: allColors
          }
        }}
        colorTracking={() => {
          googleAnalytics.reportColorClick(currentRawAnalyticsData)
        }}
        nameSpellingTracking={mode => {
          googleAnalytics.reportArbitraryEvent(
            currentRawAnalyticsData,
            mode === "audio"
              ? "spellingAudioPlayed"
              : "spellingTooltipDisplayed"
          )
        }}
        addToBag={{
          onClick: (event, options) => {
            event.preventDefault()
            return addToBag(options?.notify)
          },
          error: addToBagError
        }}
        imageZoomTracking={() => {
          googleAnalytics.reportImageZoomClick(currentRawAnalyticsData)
        }}
        storeStockButton={
          useStockInformation() ? (
            <OutlineLightButton
              fill
              loading={stockInformationLoading}
              onClick={() => {
                setStockInformationLoading(true)
                loadStockInformation()
                  .then(result => setStockInformation(result))
                  .then(() => setStockInformationVisible(true))
                  .finally(() => setStockInformationLoading(false))
              }}
            >
              {!stockInformationLoading && <HouseIcon sx={{ mr: 4 }} />}
              {t("storeAvailability", { defaultValue: "Store Availability" })}
            </OutlineLightButton>
          ) : null
        }
        notifyBackInStockClick={() => setShowNotifyBackInStock(true)}
        content={detailContents}
        recommendationCollection={collection}
        freeShippingThreshold={freeShippingThreshold}
        sizeGuide={
          <ActionLink
            translationKey="sizeGuide"
            onClick={() => setShowSizeGuide(true)}
          />
        }
      />
      {showSizeGuide && (
        <SizeGuideModal
          show
          modelDetails={modelDetails}
          product={{
            sku: currentSKU,
            productName: name,
            productDescription:
              product.attributes.detailsShortDescription || "",
            productSize: currentSize.label,
            productColor: currentColorway.name[language] || "",
            productKey: product.key,
            productImageUrl: currentImages[0] && currentImages[0].url,
            productImageTitle: currentImages[0] && currentImages[0].title,
            productPrice: price
          }}
          categories={product.categories}
          onClose={() => setShowSizeGuide(false)}
        />
      )}

      {stockInformationVisible && (
        <AvailabilityModal
          sku={currentSKU || ""}
          productName={name}
          productPrice={discountedPrice?.length > 0 ? discountedPrice : price}
          productSize={currentSize.label}
          productColor={currentColorway.name[language] || ""}
          show
          close={() => setStockInformationVisible(false)}
          addToBag={addToBag}
          image={currentImages[0] && currentImages[0].url}
          imageTitle={currentImages[0] && currentImages[0].title}
          stockInformation={stockInformation}
          inStockOnline={currentColorway.isInStock(currentSKU)}
        />
      )}

      {showNotifyBackInStock && (
        <NotifyBackInStockModal
          show
          close={() => setShowNotifyBackInStock(false)}
          product={{
            sku: currentSKU,
            productName: name,
            productDescription:
              product.attributes.detailsShortDescription || "",
            productSize: currentSize.label,
            productColor: currentColorway.name[language] || "",
            productImageUrl: currentImages[0] && currentImages[0].url,
            productImageTitle: currentImages[0] && currentImages[0].title,
            productPrice: price
          }}
        />
      )}
    </>
  )
}

export default ProductPage
