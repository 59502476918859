//jsxhook

import {
  ArtDirectedImage,
  Colors,
  ImgIXRatio,
  Label2,
  pillBoxStyle,
  pillLabelStyle,
  radii,
  zIndices
} from "@sixty-six-north/ui-system"
import React, { useState } from "react"
import { Box, Flex, ThemeUICSSObject } from "theme-ui"
import ProductCard from "../../product/components/ProductCard"
import { CoreProductInformation } from "../../product/models/DetailedProductInformation"
import { DomainCategory } from "../../product/models/DomainCategory"
import { PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicSlice } from "../PrismicModels"
import { useRecommendations } from "../PrismicProductsContext"
import { PrismicElementWrapper } from "./Layout"
import { PrismicImgixImage } from "./PrismicImgixImage"
import { usePreferredLanguage } from "i18n/StoreHooks"

const getRootCategories = (
  product: CoreProductInformation
): DomainCategory[] => {
  return product.categories.filter(
    it => it && it.ancestors && it.ancestors.length === 0
  )
}

const CurrentProductCard: React.FC<{
  productCode?: string
  productCategory?: string
  preferredColorCode?: string
  productBadge?: string
}> = ({ productCode, productCategory, preferredColorCode, productBadge }) => {
  const language = usePreferredLanguage()

  const recommendations = useRecommendations()

  const product = recommendations.productsList(productCode)
  const newProduct: CoreProductInformation = product[0] && product[0].product
  if (product[0]) {
    const rootCategories = getRootCategories(newProduct)

    const preferredProduct = productCategory
    const preferredCategory = newProduct.categories.filter(category => {
      if (category && category && category.key) {
        const parentURL = category.key.split("-")
        if (parentURL && parentURL[0] === preferredProduct) {
          return category
        } else {
          return null
        }
      } else {
        return null
      }
    })

    const currentCategorySlug =
      preferredCategory[0]?.slug[language] ||
      rootCategories[0]?.slug[language] ||
      preferredCategory[0]?.slug[language] ||
      ""

    return (
      <ProductCard
        border={false}
        product={product[0].product}
        color={preferredColorCode}
        badge={productBadge}
        categorySlug={currentCategorySlug}
      />
    )
  } else {
    return null
  }
}

export const ProductAndAssetSlice: React.FC<{ data: PrismicSlice }> = ({
  data
}) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <PrismicElementWrapper prismicStyle={data.primary}>
      <Flex
        sx={{
          flex: [null, null, 1],
          width: ["100%", null, "auto"],
          bg: Colors.grey,
          "&:after": {
            content: '""',
            pb: "100%"
          }
        }}
      >
        {data.primary.the_image && (
          <ArtDirectedImage
            noRatio={true}
            videoURL={adapter.videoURL("")}
            autoPlay={true}
            loop={true}
            muted={true}
            imageNode={
              <PrismicImgixImage
                image={data.primary.the_image}
                aspectRatio={ImgIXRatio(adapter.ratio("1:1"))}
                sizes={"40vw"}
                width={
                  data.primary.the_image.dimensions &&
                  data.primary.the_image.dimensions.width
                }
                height={
                  data.primary.the_image.dimensions &&
                  data.primary.the_image.dimensions.height
                }
              />
            }
            imageAlt={data.primary.the_image.alt}
          />
        )}
      </Flex>
      <Flex
        sx={{
          flex: [null, null, 1],
          width: ["100%", null, "auto"],
          bg: Colors.grey,
          alignItems: "center",
          justifyContent: "center",
          py: 32,
          pt: 48,
          position: "relative",
          "&:after": {
            content: '""',
            pb: "100%"
          }
        }}
      >
        <Flex
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            pt: 16,
            px: 16,
            pb: 32,
            zIndex: zIndices.page
          }}
        >
          <Flex
            sx={{
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "grey.2",
              borderRadius: "2px"
            }}
          >
            {data.items.length > 1 &&
              data.items.map((tab, index) => {
                return (
                  <Box
                    key={`tab-${index}`}
                    sx={{
                      ...(pillBoxStyle(
                        currentTab === index
                      ) as ThemeUICSSObject)
                    }}
                  >
                    <button
                      sx={{
                        variant: `buttons.accessible`,

                        height: 28,
                        px: 12,
                        ":focus": {
                          outline: "none"
                        },
                        ...(pillLabelStyle(
                          currentTab === index
                        ) as ThemeUICSSObject),
                        width: "100%"
                      }}
                      onClick={() => setCurrentTab(index)}
                    >
                      <Label2 sx={{ position: "relative", top: "-2px" }}>
                        {tab.tab}
                      </Label2>
                    </button>
                  </Box>
                )
              })}
          </Flex>
        </Flex>
        <Box
          sx={{
            width: "80%"
          }}
        >
          {data.items[currentTab].product_id &&
            data.items[currentTab].product_id?.productCode && (
              <CurrentProductCard
                key={`product-asset-current-${currentTab}`}
                productCode={data.items[currentTab].product_id?.productCode}
                productCategory={data.items[currentTab].product_category}
                productBadge={data.items[currentTab].product_badge}
                preferredColorCode={data.items[currentTab].preferred_color_code}
              />
            )}
        </Box>
      </Flex>
    </PrismicElementWrapper>
  )
}
