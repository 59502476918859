//jsxhook

import { InPageBar } from "@sixty-six-north/ui-system"
import Link from "components/Link"
import _isEqual from "lodash/isEqual"
import { useLocaleAwareLinks, usePreferredLanguage } from "i18n/StoreHooks"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "theme-ui"
import {
  CoreProductInformation,
  productUri
} from "../../product/models/DetailedProductInformation"
import { DomainCategory } from "../../product/models/DomainCategory"
import { PrismicItemAdapter, PrismicPrimaryAdapter } from "../PrismicAdapters"
import { PrismicItem, PrismicSlice } from "../PrismicModels"
import { useRecommendations } from "../PrismicProductsContext"
import { PrismicElementWrapper } from "./Layout"
import { PrismicDocumentLink } from "./PrismicLink"

const getRootCategories = (
  product: CoreProductInformation
): DomainCategory[] => {
  return product.categories.filter(it => it.ancestors.length === 0)
}

const getSubCategories = (
  product: CoreProductInformation
): DomainCategory[] => {
  return product.categories.filter(it => it.ancestors.length > 0)
}

const NavigationBarLink: React.FC<{ item: PrismicItem }> = ({ item }) => {
  const linkBuilder = useLocaleAwareLinks()
  const language = usePreferredLanguage()
  const recommendedProducts = useRecommendations()
  const [products, setProducts] = useState(recommendedProducts)
  useEffect(() => {
    if (!_isEqual(products, recommendedProducts)) {
      setProducts(recommendedProducts)
    }
  }, [recommendedProducts])

  const adapter = new PrismicItemAdapter(item)
  const EmptyLink = () => (
    <Box>
      <a>{adapter.label("")}</a>
    </Box>
  )
  if (item.product_id) {
    const product = products.productsList(item.product_id?.productCode)
    if (product[0]) {
      const coreProductInformation: CoreProductInformation =
        product[0] && product[0].product

      const rootCategories = getRootCategories(coreProductInformation)
      const subCategories = getSubCategories(coreProductInformation)
      const preferredProduct = item.product_category
      const preferredCategory = coreProductInformation.categories.filter(
        category => {
          if (category && category && category.key) {
            const parentURL = category.key.split("-")
            if (parentURL && parentURL[0] === preferredProduct) {
              return category
            } else {
              return null
            }
          } else {
            return null
          }
        }
      )
      const currentCategorySlug =
        preferredCategory[0]?.slug[language] ||
        rootCategories[0]?.slug[language] ||
        preferredCategory[0]?.slug[language] ||
        ""
      const currentSubCategorySlug = subCategories[0]?.slug[language] || ""

      const url = linkBuilder.productProjectionLink(
        currentCategorySlug,
        currentSubCategorySlug,
        productUri(coreProductInformation)
      )

      return (
        <Box>
          <Link {...url}>
            <span>{adapter.label("")}</span>
          </Link>
        </Box>
      )
    } else {
      return <EmptyLink />
    }
  } else if (item.link?.link_type === "Web") {
    return (
      <Box>
        <a href={item.link.url}>
          <span>{adapter.label("")}</span>
        </a>
      </Box>
    )
  } else if (item.link?.link_type === "Document") {
    return (
      <Box>
        <PrismicDocumentLink documentId={item.link?.id}>
          <span>{adapter.label("")}</span>
        </PrismicDocumentLink>
      </Box>
    )
  }
  return <EmptyLink />
}

export const NavigationBarSlice: React.FC<{
  data: PrismicSlice
}> = ({ data }) => {
  const adapter = new PrismicPrimaryAdapter(data.primary)
  const { t } = useTranslation("layout")

  const characterLength: number[] = []
  data.items.forEach((item: PrismicItem) => {
    const itemAdapter = new PrismicItemAdapter(item)
    if (item.label) characterLength.push(itemAdapter.label("").length as number)
  })

  return (
    <PrismicElementWrapper prismicStyle={data.primary} ignoreColorMode={true}>
      <InPageBar
        translations={{
          viewMore: t("viewMore")
        }}
        title={adapter.navigation_title("")}
        subtitle={adapter.subtitle("")}
        alignLinksRight={data.primary.align_links_right}
        itemLengths={characterLength}
      >
        {data.items.map((item, idx) => (
          <NavigationBarLink key={idx} item={item} />
        ))}
      </InPageBar>
    </PrismicElementWrapper>
  )
}
